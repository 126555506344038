import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { CombinedProvider } from "./context/CombinedProvider";
import BrandingWrapper from "./shared/BrandingWrapper";
import useExternalCss from "./shared/useExternalCss";

const root = ReactDOM.createRoot(document.getElementById("root"));


const AppWrapper = () => {
  useExternalCss(`/styles.css`);
  return (
    <BrandingWrapper>
      <App />
    </BrandingWrapper>
  );
};

root.render(
  <BrowserRouter>
    <CombinedProvider>
      <AppWrapper />
    </CombinedProvider>
  </BrowserRouter>
);

reportWebVitals();