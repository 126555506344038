import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import useFetchToken from '../hooks/useFetchToken';
import { useParams } from "react-router-dom";
import { useAnonymous } from '../context/anonymousContext';
import useSendOtp from '../hooks/useSendOtp';
const OtpForm = () => {
  const { slug,id,stepslug } = useParams();
  // const {steps} = useAnonymous();

  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false); // State for submit button
  const [timer, setTimer] = useState(120); // Default 2 minutes countdown
  const navigate = useNavigate();
  const location = useLocation();
  const token=sessionStorage.getItem('token')
  const { state } = location;
  const customerData = state?.customerData;
  const { sendOtp, loading, error } = useSendOtp(); 
  const requestId = state?.requestId || id ;
  const isNewRequest=state?.isNewRequest;
  // console.log(isNewRequest,"hay");
  useEffect(() => {
    if (!isNewRequest) {
      sendOtp(id); // Call sendOtp if id exists in params
    }
  }, []);

  useEffect(() => {
    const lastSentTime = localStorage.getItem('lastSentTime');

    if (lastSentTime) {
      const timeElapsed = Math.floor((Date.now() - lastSentTime) / 1000);
      const remainingTime = 120 - timeElapsed;

      if (remainingTime > 0) {
        setTimer(remainingTime);
        setIsResendDisabled(true);
      } else {
        setIsResendDisabled(false);
      }
    } else {
      setIsResendDisabled(true);
    }
  }, []);

  useEffect(() => {
    if (isResendDisabled && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setIsResendDisabled(false);
    }
  }, [isResendDisabled, timer]);

  const onFinish = async (values) => {
    setIsSubmitDisabled(true); // Disable submit button

    if (!requestId) {
      message.error('Request ID is missing.');
      setIsSubmitDisabled(false); // Re-enable submit button if there's an error
      return;
    }
    // if (loading) {
    //   message.info('Fetching token, please wait...');
    //   setIsSubmitDisabled(false); // Re-enable submit button if there's an error
    //   return;
    // }

    // if (error) {
    //   message.error('Unable to submit form due to token retrieval failure.');
    //   setIsSubmitDisabled(false); // Re-enable submit button if there's an error
    //   return;
    // }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/validate-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          request_id: requestId ,
          otp_code: values.otp,
        }),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        message.success('OTP verified successfully!');
        // navigate(`/services/${slug}/${steps[0]}`, { state: { customerData } });
        if (id) {
          navigate(`/services/${slug}/${stepslug}/${id}`,{ state: { customerData } });
          sessionStorage.setItem('verified', true);
        } else {
          navigate(`/services/${slug}/${stepslug}`, { state: { customerData } });
        }
      } else {
        message.error('Failed to verify OTP.');
        setIsSubmitDisabled(false); // Re-enable submit button if verification fails
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while verifying the OTP.');
      setIsSubmitDisabled(false); // Re-enable submit button if there's an error
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    setIsSubmitDisabled(false); // Re-enable submit button if form submission fails
  };

  const handleResendOtp = async () => {
    if (!requestId) {
      message.error('Request ID is missing.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/resend-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          request_id: requestId ,
        }),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        message.success('OTP has been resent!');
        setIsResendDisabled(true);
        setTimer(120); // Restart 2-minute countdown

        // Store the timestamp when the OTP is resent
        localStorage.setItem('lastSentTime', Date.now());
      } else {
        message.error('Failed to resend OTP.');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while resending the OTP.');
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h2 style={styles.title}>Enter OTP</h2>
        <Form
          name="otp_form"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="OTP"
            name="otp"
            rules={[
              {
                required: true,
                message: 'Please input your OTP!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (!/^\d+$/.test(value)) {
                    return Promise.reject(new Error('OTP must be numeric!'));
                  }
                  if (value.length !== 6) {
                    return Promise.reject(new Error('OTP must be 6 digits!'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="OTP" maxLength={6} />
          </Form.Item>
          <Form.Item>
            <Row justify="space-between">
              <Col>
                <Button 
                  type="default" 
                  onClick={handleResendOtp} 
                  disabled={isResendDisabled}>
                  Resend {isResendDisabled && `(${timer}s)`}
                </Button>
              </Col>
              <Col>
                <Button 
                  type="primary" 
                  htmlType="submit" 
                  disabled={isSubmitDisabled}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f2f5',
  },
  formContainer: {
    width: '400px',
    padding: '20px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
  },
};

export default OtpForm;
