import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';
import { adToBs } from '@sbmdkl/nepali-date-converter';
import { CloseCircleOutlined } from '@ant-design/icons';

const NepaliDatePicker = (props) => {
  const { value, onChange, options } = props;
  const [calendarKey, setCalendarKey] = useState(Date.now());
  
  const [date, setDate] = useState(options?.initialValue || value || '');

  useEffect(() => {
    // console.log("NepaliDatePicker received updated value:", value);
    if (value) {
      setDate(value);
    }
  }, [value]);

  const handleDateChange = ({ bsDate }) => {
    setDate(bsDate);
    onChange(bsDate);
    options.onDateChange && options.onDateChange(bsDate);
  };

  const handleClearDate = () => {
    setDate(''); // Clear the date
    onChange(''); // Notify parent component about the clear action
  };

  let maxDateBS;
  try {
    const todayAD = new Date();
    const eighteenYearsAgoAD = new Date();
    eighteenYearsAgoAD.setFullYear(todayAD.getFullYear() - 18);
    maxDateBS = adToBs(eighteenYearsAgoAD.toISOString().split('T')[0]);
  } catch (error) {
    console.error("Date conversion error:", error.message);
  }

  return (
    <Form.Item>
      <div style={{ position: 'relative', width: '100%' }}>
        <Calendar
         key={calendarKey}
          onChange={handleDateChange}
          defaultDate={date}
          value={date} // Set value here
          theme="light"
          style={{
            width: "100%",
            borderRadius: "4px",
            border: "1px solid #d9d9d9",
            padding: "4px 11px",
            height: "32px"
          }}
          language="en"
          maxDate={maxDateBS} // Disable dates after 18 years ago in BS
        />
        {date && (
          <CloseCircleOutlined
            style={{
              position: 'absolute',
              right: 10,
              top: '50%',
              transform: 'translateY(-50%)',
              fontSize: '16px',
              cursor: 'pointer',
              color: 'rgba(0, 0, 0, 0.45)',
            }}
            onClick={handleClearDate}
          />
        )}
      </div>
    </Form.Item>
  );
};

export default NepaliDatePicker;

