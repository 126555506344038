import React from 'react';
import { Card, Button, Typography, message } from 'antd';
import { CopyOutlined, HomeOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { slug } = useParams();
  // Getting data from the location state
  const draftUrl = state?.draftUrl;
  const submissionId = state?.id;
  const submissionType = state?.submissionType;
  
  // Handler for copying text (either draft URL or submission ID)
  const handleCopy = () => {
    const textToCopy = submissionType === 'draft' ? draftUrl : submissionId;
    navigator.clipboard.writeText(textToCopy)
      .then(() => message.success(`${submissionType === 'draft' ? 'Draft URL' : 'Submission ID'} copied to clipboard!`))
      .catch(() => message.error(`Failed to copy ${submissionType === 'draft' ? 'Draft URL' : 'Submission ID'}.`));
  };

  // Navigate to home
  const handleGoHome = () => {
    navigate(`/services/${slug}`)
  };

  return (
    <div style={styles.container}>
      <Card style={styles.card}>
        <Title level={3}>Submission Successful</Title>
        <Paragraph>Your submission has been received successfully!</Paragraph>
        
        {/* Conditionally render based on submissionType */}
        {submissionType === 'draft' ? (
          <Paragraph>
            <strong>Draft URL: </strong>
            <a href={draftUrl} target="_blank" rel="noopener noreferrer">
              {draftUrl}
            </a>
            <Paragraph>Use this Url For Final form Submission</Paragraph>
          </Paragraph>
        ) : (
          <Paragraph style={{marginTop:15}}>
            <strong>Submission ID: </strong> {submissionId}
            <strong><Paragraph>Note:Use this Submission ID To Edit/Repair Your Form</Paragraph></strong>
          </Paragraph>
        )}

        <Button
          type="primary"
          icon={<CopyOutlined />}
          onClick={handleCopy}
          style={styles.copyButton}
        >
          {submissionType === 'draft' ? 'Copy Draft URL' : 'Copy Submission ID'}
        </Button>

        <Button
          type="default"
          icon={<HomeOutlined />}
          onClick={handleGoHome}
          style={styles.homeButton}
        >
          Go Home
        </Button>
      </Card>
    </div>
  );
};

// Styling
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f2f5',
  },
  card: {
    width: 600,
    padding: '20px',
    textAlign: 'center',
  },
  copyButton: {
    marginTop: '20px',
    marginRight: '10px',
  },
  homeButton: {
    marginTop: '20px',
  },
};

export default SuccessPage;
