import React, { useEffect, useState } from "react";
import { DatePicker, message } from "antd";
import dayjs from "dayjs";
import { useAnonymous } from "../context/anonymousContext";
const CustomDatePicker = (props) => {
  const { onChange, value, options } = props;
  // console.trace(value,"english")
  const { minDate, maxDate } = options;
  const [error, setError] = useState("");
  const {status } = useAnonymous();
  const handleDateChange = (date) => {
    const selectedDate = dayjs(date?.$d).format("YYYY-MM-DD");
    // console.log("CustomDatePicker handleDateChange called with:", selectedDate);
    const age = dayjs().diff(selectedDate, "years");

    if (age < 18) {
      setError("You must be at least 18 years old.");
      message.error("You must be at least 18 years old.");
    } else {
      setError("");
      onChange(selectedDate); // Update the form data here directly
      options.onDateChange && options.onDateChange(selectedDate);
  

    }
  };

  // useEffect(() => {
  //   console.log("CustomDatePicker value:", value);
  //   if (value) {
  //     options.onDateChange && options.onDateChange(value);
  //   }
  // }, [value]); 
  return (
    <div style={{ width: "100%" }}>
      <DatePicker
       placeholder="Select the Date"
       disabled={status === 'submitted'}
       value={
        value && dayjs(value).isValid() && dayjs(value).format('YYYY-MM-DD') !== dayjs().format('YYYY-MM-DD')
          ? dayjs(value)
          : null // This ensures that today's date or invalid date won't set by default
      }
      // Ensure proper dayjs object creation
        onChange={handleDateChange}
        format="YYYY-MM-DD"
        style={{ width: "100%" }}
        // disabledDate={(current) => {
        //   return (
        //     (current && current.isAfter(maxDate)) ||
        //     (current && current.isBefore(minDate))
        //   );
        // }}
        minDate={minDate ? dayjs(minDate):null}
        maxDate={maxDate ? dayjs(maxDate):null}
      />
      {error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  );
};

export default CustomDatePicker;