
import React, { useEffect, useState } from "react";
import { renderToString } from 'react-dom/server';
import { FooterWidget, HeaderWidget, LeftSidebarWidget, RightSidebarWidget } from "../widgets/BrandingWidgets";

const BrandingWrapper = ({ children }) => {

  const [headerHtml, setHeaderHtml] = useState('');
  const [footerHtml, setFooterHtml] = useState('');
  const [leftSidebarHtml, setLeftSidebarHtml] = useState('');
  const [rightSidebarHtml, setRightSidebarHtml] = useState('');
  const [displayFlags, setDisplayFlags] = useState({
    header: true,
    footer: true,
    leftSidebar: true,
    rightSidebar: true
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `/branding.js`;
    script.async = true;

    script.onload = () => {
      if (window.createBrandingElements) {
        const widgets = {
          header: () => renderToString(<HeaderWidget />),
          footer: () => renderToString(<FooterWidget />),
          leftSidebar: () => renderToString(<LeftSidebarWidget />),
          rightSidebar: () => renderToString(<RightSidebarWidget />),
        };

        const { headerHtml, footerHtml, leftSidebarHtml, rightSidebarHtml, displayFlags: newDisplayFlags } = window.createBrandingElements(widgets);
        setHeaderHtml(headerHtml);
        setFooterHtml(footerHtml);
        setLeftSidebarHtml(leftSidebarHtml);
        setRightSidebarHtml(rightSidebarHtml);
        setDisplayFlags(newDisplayFlags);
      }
    };

    document.body.appendChild(script);

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <>
      {displayFlags.header && <div dangerouslySetInnerHTML={{ __html: headerHtml }} />}
      <div style={{ display: 'flex' }}>
        {displayFlags.leftSidebar && <div dangerouslySetInnerHTML={{ __html: leftSidebarHtml }} />}
        <main style={{ flex: 1 }}>{children}</main>
        {displayFlags.rightSidebar && <div dangerouslySetInnerHTML={{ __html: rightSidebarHtml }} />}
      </div>
      {displayFlags.footer && <div dangerouslySetInnerHTML={{ __html: footerHtml }} />}
    </>
  );
};
export default BrandingWrapper