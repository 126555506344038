import React, { useEffect, useState } from 'react';

export const LogoWidget = () => (
  <img src="https://siteadmin.nabilbank.com/assets/backend/uploads/logo/nabil logo.svg" alt="Logo" className="logo-widget" />
);

export const TimeWidget = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer); // Clean up the timer on unmount
  }, []);

  return (
    <div className="time-widget">
      <h4>Current Time</h4>
      {currentTime.toLocaleDateString()} {currentTime.toLocaleTimeString()}
    </div>
  );
};
export const NavigationLinksWidget = () => (
  <nav className="navigation-links-widget">
    <a href="#">Individual</a>
    <a href="#">Business</a>
    <a href="#">Services & Payments</a>
    <a href="#">nBank</a>
  </nav>
);

export const ContactInfoWidget = () => (
  <span className="contact-info-widget">+977 1 5971871, +977 1 5970015</span>
);

export const QuickLinksWidget = () => (
  <div className="quick-links-widget">
    <a href="#">Quick Links</a>
    <a href="#">EN</a>
  </div>
);

export const HeaderServicesWidget = () => (
  <div className="header-services-widget">
    <div>Cards</div>
    <div>Merchant Acquiring</div>
    <div>Digital Solutions</div>
  </div>
);

export const FooterCopyrightWidget = () => (
  <p className="footer-copyright-widget">&copy; {new Date().getFullYear()} Nabil Bank. All rights reserved.</p>
);

export const LeftSidebarWidget = () => (
  <aside className="left-sidebar-widget">
    <h3>Left Sidebar</h3>
    <ul>
      <li><a href="#">Link 1</a></li>
      <li><a href="#">Link 2</a></li>
      <li><a href="#">Link 3</a></li>
    </ul>
  </aside>
);

export const RightSidebarWidget = () => (
  <aside className="right-sidebar-widget">
    <h3>Right Sidebar</h3>
    <div>
      <h4>Services</h4>
      <ul>
        <li>Service 1</li>
        <li>Service 2</li>
        <li>Service 3</li>
      </ul>
    </div>
    <QuickLinksWidget />
    <ContactInfoWidget />
      <TimeWidget />
  </aside>
);

export const HeaderWidget = () => (
  <header className="header-widget">
    <div>
      <nav>
        <div>
          <NavigationLinksWidget />
          <ContactInfoWidget />
          <QuickLinksWidget />
        </div>
        <hr />
        <div>
        {/* <TimeWidget /> */}
          <LogoWidget />
          <HeaderServicesWidget />
        </div>
      </nav>
    </div>
  </header>
);

export const FooterWidget = () => (
  <footer className="footer-widget">
    <div>
      <FooterCopyrightWidget />
    </div>
  </footer>
);
