import React, { createContext, useContext, useState, useEffect } from 'react';
import { message } from 'antd';

// Create Token Context
const TokenContext = createContext();

// Custom hook to use the Token Context
export const useToken = () => useContext(TokenContext);

const isTokenExpired = (expiry) => {
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime > expiry;
};

const fetchToken = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: process.env.REACT_APP_EMAIL,
        password: process.env.REACT_APP_PASSWORD,
      }),
    });

    const result = await response.json();
    if (response.ok) {
      const { token: newToken, expires_at } = result;
      sessionStorage.setItem('token', newToken);
      sessionStorage.setItem('expires_at', expires_at);
      return { token: newToken, expiresAt: expires_at };
    } else {
      message.error('Failed to retrieve token.');
      return null;
    }
  } catch (error) {
    console.error('Error fetching token:', error);
    message.error('An error occurred while fetching the token.');
    return null;
  }
};

export const TokenProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeToken = async () => {
      const storedToken = sessionStorage.getItem('token');
      const storedExpiration = sessionStorage.getItem('expires_at');

      if (storedToken && storedExpiration) {
        if (!isTokenExpired(storedExpiration)) {
          setToken(storedToken);
        } else {
          const newToken = await fetchToken();
          if (newToken) setToken(newToken.token);
        }
      } else {
        const newToken = await fetchToken();
        if (newToken) setToken(newToken.token);
      }

      setLoading(false);
    };

    initializeToken();
  }, []);

  return (
    <TokenContext.Provider value={{ token, loading }}>
      {children}
    </TokenContext.Provider>
  );
};
